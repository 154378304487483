<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      :color="$vuetify.theme.isDark ? 'primary' : 'white'"
      shaped
    >
      <v-list-item
        v-for="item in items"
        :key="item.name"
        :to="item.to"
        :exact="item.name === 'Home'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.label" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-img
              :src="require(`@/assets/paydone/${$i18n.locale == 'fr' ? 'en.jpeg' : 'fr.png'}`)"
              width="45px"
              max-width="45px"
              height="25px"
              max-height="45px"
              :ripple="false"
              @click.prevent="$i18n.locale = ($i18n.locale== 'fr' ? 'en' : 'fr')"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
